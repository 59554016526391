import { render, staticRenderFns } from "./PopupInstallExtension.vue?vue&type=template&id=7877efe0&scoped=true&"
import script from "./PopupInstallExtension.vue?vue&type=script&lang=js&"
export * from "./PopupInstallExtension.vue?vue&type=script&lang=js&"
import style0 from "./PopupInstallExtension.vue?vue&type=style&index=0&id=7877efe0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7877efe0",
  null
  
)

export default component.exports