<template>
  <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="currentColor">
    <path d="M4 7.5C3.07174 7.5 2.1815 7.13125 1.52513 6.47487C0.868749 5.8185 0.5 4.92826 0.5 4C0.5 3.07174 0.868749 2.1815 1.52513 1.52513C2.1815 0.868749 3.07174 0.5 4 0.5C4.92826 0.5 5.8185 0.868749 6.47487 1.52513C7.13125 2.1815 7.5 3.07174 7.5 4C7.5 4.92826 7.13125 5.8185 6.47487 6.47487C5.8185 7.13125 4.92826 7.5 4 7.5ZM4 8C5.06087 8 6.07828 7.57857 6.82843 6.82843C7.57857 6.07828 8 5.06087 8 4C8 2.93913 7.57857 1.92172 6.82843 1.17157C6.07828 0.421427 5.06087 0 4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4C0 5.06087 0.421427 6.07828 1.17157 6.82843C1.92172 7.57857 2.93913 8 4 8V8Z" fill="currentColor"/>
    <path d="M4.46498 3.294L3.31998 3.4375L3.27898 3.6275L3.50398 3.669C3.65098 3.704 3.67998 3.757 3.64798 3.9035L3.27898 5.6375C3.18198 6.086 3.33148 6.297 3.68298 6.297C3.95548 6.297 4.27198 6.171 4.41548 5.998L4.45948 5.79C4.35948 5.878 4.21348 5.913 4.11648 5.913C3.97898 5.913 3.92898 5.8165 3.96448 5.6465L4.46498 3.294ZM4.49998 2.25C4.49998 2.38261 4.4473 2.50979 4.35353 2.60355C4.25976 2.69732 4.13258 2.75 3.99998 2.75C3.86737 2.75 3.74019 2.69732 3.64642 2.60355C3.55265 2.50979 3.49998 2.38261 3.49998 2.25C3.49998 2.11739 3.55265 1.99021 3.64642 1.89645C3.74019 1.80268 3.86737 1.75 3.99998 1.75C4.13258 1.75 4.25976 1.80268 4.35353 1.89645C4.4473 1.99021 4.49998 2.11739 4.49998 2.25Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconInformation"
}
</script>

<style scoped>

</style>
